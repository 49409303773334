<template>
    <nav class="nav">
        <ul class="nav_inner">
            <li class="title" v-bind:class="{ white: CURRENT_SECTION === 'contact' }">
                <a href="/" v-on:click="goBackUp">FEMI</a>
            </li>
            <div class="nav_items">
                <NavItem
                    :nav_link="'/'"
                    :nav_text="'Portfolio'"
                    :color="CURRENT_SECTION === 'contact' ? 'white' : '#268976'"
                />
                <NavItem
                    :nav_link="'https://medium.com/@iamkeyiano'"
                    href
                    :nav_text="'Blog'"
                    :color="CURRENT_SECTION === 'contact' ? 'white' : '#268976'"
                />
                <NavItem
                    :nav_link="'/store'"
                    :nav_text="'Store'"
                    :color="CURRENT_SECTION === 'contact' ? 'white' : '#268976'"
                />
            </div>
            <li class="d_flex center contact" v-if="CURRENT_SECTION !== 'contact'">
                <a href="#contactMe">
                    <span>Contact Me</span>
                    <i class="fas fa-long-arrow-alt-right"></i>
                </a>
            </li>

            <li class="strokes d_flex_col align_flex_end" @click.prevent="toggle">
                <div
                    class="stroke first"
                    v-bind:class="{ topStroke: showNav, white: CURRENT_SECTION === 'contact' }"
                ></div>
                <div
                    class="stroke second"
                    v-bind:class="{ middleStroke: showNav, white: CURRENT_SECTION === 'contact' }"
                ></div>
                <div
                    class="stroke third"
                    v-bind:class="{ bottomStroke: showNav, white: CURRENT_SECTION === 'contact' }"
                ></div>
            </li>
        </ul>
        <div class="right_underlay" v-if="CURRENT_SECTION !== 'contact'">
            <svg width="457" height="237" viewBox="0 0 457 237" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                    d="M202.804 131.349C54.2653 137.746 5.7102 46.4482 0 0H457V237C434.159 199.118 351.343 124.953 202.804 131.349Z"
                    fill="#01493B"
                />
                <path
                    d="M266.5 128.712C144.5 127.446 77.3333 42.3765 59 0H457V163C444.333 152.098 388.5 129.978 266.5 128.712Z"
                    fill="#268976"
                />
            </svg>
        </div>

        <div class="nav_overlay d_flex_col center" v-if="showNav">
            <div class="d_flex_col center">
                <NavItem :nav_link="'/'" :nav_text="'Portfolio'" :font_size="'25px'" @click="toggle" />
                <NavItem
                    :nav_link="'https://medium.com/@iamkeyiano'"
                    href
                    :nav_text="'Blog'"
                    :font_size="'25px'"
                    @click="toggle"
                />
                <NavItem :nav_link="'/store'" :nav_text="'Store'" :font_size="'25px'" @click="toggle" />
                <li>
                    <a href="#contactMe" @click="toggle" class="contact_mobile">Contact Me</a>
                </li>
            </div>
        </div>
    </nav>
</template>

<script>
const NavItem = () => import('@/components/NavItem')
export default {
    components: {
        NavItem,
    },

    data() {
        return {
            showNav: false,
        }
    },

    methods: {
        toggle() {
            this.showNav = this.showNav ? false : true
        },
        goBackUp() {
            window.scrollTo({ top: 0, behavior: 'smooth' })
        },
    },

    computed: {
        CURRENT_SECTION: function() {
            return this.$store.state.CURRENT_SECTION
        },
    },
}
</script>

<style lang="scss" scoped>
.nav {
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 999;

    .nav_inner {
        display: flex;
        justify-content: space-between;
        margin: 0;
        padding: 20px 0;
        width: 85%;
        margin: auto;

        .title {
            font-size: 30px;
            font-weight: 900;
            letter-spacing: 0.1em;
            color: $secondary;
        }

        .nav_items {
            display: flex;
            align-items: center;
            justify-content: space-between;
            width: 30%;
            padding: 0 10px;
        }

        .contact {
            color: rgb(221, 221, 221) !important;
            i {
                padding-left: 10px;
            }
        }
    }

    .right_underlay {
        position: absolute;
        right: 0;
        top: 0;
        z-index: -1;
        right: 0;

        svg {
            float: right;
            width: 80%;
            height: 80%;
        }
    }

    .strokes {
        position: relative;
        z-index: 999999;
        .stroke {
            height: 1px;
            background: $secondary;
            margin: 5px 0px;
        }

        .white {
            background: white;
        }

        .first {
            width: 40px;
        }

        .second {
            width: 30px;
        }

        .third {
            width: 20px;
        }

        .middleStroke {
            animation: fadeAway 0.2s ease-in-out 1 normal both;
            background: $secondary;
            // position: absolute;
        }

        .topStroke {
            animation: animatedStrokeTop 0.3s ease-in-out 1 normal both;
            position: absolute;
            margin-top: 18px;
            background: $secondary;
        }

        .bottomStroke {
            animation: animatedStrokeBottom 0.3s ease-in-out 1 normal both;
            position: absolute;
            margin-top: 18px;
            background: $secondary;
        }
    }

    .nav_overlay {
        position: fixed;
        min-height: 100vh;
        width: 100vw;
        background-color: rgba(255, 255, 255, 1);
        top: 0;
        z-index: 999;

        animation: FadeIn 0.4s ease-in-out 1 normal both;

        li {
            padding: 10px 0;
        }

        .contact_mobile {
            padding: 20px;
            color: $secondary;
            text-decoration: underline;
            font-size: 18px;
        }
    }
}

@media screen and (max-width: 768px) {
    .right_underlay {
        display: none;
    }

    .contact span,
    .contact i {
        color: $secondary;
    }
}

@media screen and (min-width: 768px) {
    .strokes {
        display: none;
    }
}

@media screen and (max-width: 600px) {
    .nav_items {
        display: none !important;
    }

    .contact {
        display: none;
    }
}

.white {
    color: white !important;
}
</style>
